<template>
  <div class="order-item">
    <div>
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getMessageList(active)"
      >
        <div class="order-head" v-for="(item, index) in data" :key="index">
          <!-- 头 -->
          <div class="order-floor">
              <span class="shop-img">
                <img src="../../../assets/icon/store-touxiang-icon@2x.png" alt="" />
              </span>
            <span class="order-name">{{ item.storeName }}</span>
            <span class="order-status">{{ status.get(item.tradeStatus) }}</span>
          </div>
          <!-- 商品信息 -->
          <Order :card="item" />
          <!-- 底-->
          <div class="order-footer">
            <p class="order-title">
              共{{ item.totalQuantity }}件商品 订单总额：￥{{item.payableAmount}}
            </p>
            <div class="buttonLayout">
              <button
                  class="order-btn1"
                  v-if="item.tradeStatus === 45"
                  @click="() => triggerTradeOrder(item)"
              >
                确认收货
              </button>
              <button
                  class="order-btn1"
                  v-if="item.tradeStatus === 5"
                  @click="() => paybank(item)"
              >
                查看打款信息
              </button>
              <button class="order-btn2" @click="() => details(item)">
                查看详情
              </button>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-dialog v-model="show" :showCancelButton="false">
      <div class="order-paymessage">
        <p>
          请向以下商家汇款<span>
            ￥{{ (paymessage.totalActualAmount || 0).toFixed(2) }}&nbsp;</span
        >用于支付货款
        </p>
        <div
          class="paymessage-name"
          v-for="(payitem, index) in paymessage.orderList"
          :key="index"
        >
          <p>
            户名：<span>{{ payitem.bank.name }}</span>
          </p>
          <p>
            开户行：<span> {{ payitem.bank.bankName }}</span>
          </p>
          <p>
            账户：<span> {{ payitem.bank.bankAccount }}</span>
          </p>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Order from "./card.vue";
import { Toast } from "vant";

import { orderBusiness, triggerTrade, getPaymentinformation } from "../service";

export default {
  components: { Order },
  props: { title: String, active: String },
  data() {
    return {
      data: [],
      showCancelButton: false,
      paymessage: {},
      show: false,
      loading: false,
      finished: false,
      currentPage: 0,
      status: new Map([
        // [1, "初始化"],
        [5, "待付款"],
        [25, "待审核"],
        [40, "待发货"],
        [44, "部分发货"],
        [45, "待收货"],
        [0, "交易完成"],
        [-1, "交易取消"]
      ])
    };
  },
  mounted() {
    this.getMessageList(this.active)
  },
  methods: {
    async paybank(item) {
      await getPaymentinformation(item.parentOrderNo).then((res) => {
        if (res.status === "0") {
          this.paymessage = res.data;
          this.show = true;
        } else {
          Toast(res.msg);
        }
      });
    },
    async getMessageList(active) {
      this.loading = true;
      await orderBusiness(active, ++this.currentPage).then((respones) => {
        this.loading = false;
        if (respones.status === "0") {
          this.data = this.data.concat(respones.data.items);
          this.currentPage = respones.data.page;
          if (respones.data.items.length < 10) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    details(item) {
      const { orderNo } = item;
      this.$router.push({
        path: "/orderdetails",
        query: { orderNo, isDetail: true }
      });
    },
    async triggerTradeOrder(item) {
      const res = await triggerTrade(item.orderNo);
      if (res.status === "0") {
        Toast("收货成功");
        this.getMessageList(this.active);
      } else {
        Toast(res.msg);
      }
    }
  }
  //   async triggerTradeOrder(item) {
  //     this.$emit("triggerTradeOrder", item);
  //   },
  // },
};
</script>
<style lang="less" scoped>
.order-item {
  padding: 0 12px;
  .order-head {
    margin-top: 8px;
    border-radius: 12px;

    .order-floor {
      display: flex;
      width: 100%;
      height: 48px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background-color: #fff;

      .shop-img {
        width: 48px;
        padding: 8px;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .order-name {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        line-height: 48px;
      }

      .order-status {
        width: 50px;
        color: #ff9e48;
        font-size: 12px;
        line-height: 48px;
      }
    }
  }

  .order-footer {
    padding: 8px 0;
    background-color: rgba(255, 255, 255, 1);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .order-title {
      width: 100%;
      margin: 0;
      padding: 8px 23px 8px 0;
      text-align: right;
      font-weight: 400;
      font-size: 12px;
      color: #666;
    }

    .buttonLayout {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    button {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid rgba(228, 228, 228, 1);
      height: 30px;
      font-weight: 400;
      font-size: 12px;
      padding: 0 8px;
      line-height: 20px;
    }

    .order-btn1 {
      margin-right: 10px;
      color: #999;
    }

    .order-btn2 {
      margin-right: 16px;
      padding: 0 12px;
      color: #0095da;
      border: 1px solid #0095da;
    }
  }

  .van-dialog {
    padding-left: 20px !important;
    border-radius: 8px !important;
    font-size: 15px !important;

    .order-paymessage {
      color: #aaaaaa;

      p span {
        color: #d9001b;
      }
    }

    .paymessage-name p span {
      color: #f5a804;
    }
  }
}
</style>
